import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Form, FormGroup, Col, Input, Label, Button, FormFeedback } from 'reactstrap';
import { TooltipHelper } from './TooltipHelper';
import { LanguageContext } from './LanguageContext';
import { CurrencyContext, CurrencyHelper } from './CurrencyContext';
import SimpleReactValidator from 'simple-react-validator';

class ReturnOnInvestment extends Component {
    constructor(props) {
        super(props);
        //this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCalculateReturns = this.handleCalculateReturns.bind(this);

        this.firstColSize = props.firstColSize === undefined ? 4 : props.firstColSize;
        this.secondColSize = props.secondColSize === undefined ? 8 : props.secondColSize;

        this.validator = new SimpleReactValidator({
            element: message => <FormFeedback tooltip>{message}</FormFeedback>
        });
        this.validator.showMessages();

        this.state = {
            "parking-fees": 27.445,
            "paying-fees": 7,
            "week-size": 77,
            "occupancy": 72,
            "current-success-rate": 30,
            "expected-success-rate": 90
        };
    }

    handleCalculateReturns(e) {
        this.props.onCalculateReturns(this.state);
        e.preventDefault();
    }

    handleInputChange(applyCurrency, event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let exchangeRate = this.context.exchangeRate;

        this.setState({
            [name]: applyCurrency ? CurrencyHelper.convertFrom(value, exchangeRate) : (value * 1)
        });
    }

    createInput(id, applyCurrency, min, max, type, translations, greaterThan) {
        let rangeMessage = translations["validator-decimal-range"];
        let step = 0.01;

        if (type === "integer") {
            rangeMessage = translations["validator-integer-range"];
            step = 1;
        }

        rangeMessage = rangeMessage.replace('{0}', min);
        rangeMessage = rangeMessage.replace('{1}', max);
        let minMessage = rangeMessage;

        if (greaterThan !== undefined) {
            min = greaterThan + 1;
            minMessage = translations["validator-greater-than"].replace("{0}", greaterThan);
        }

        let rules = 'required|max:' + max + ',num|min:' + min + ',num|' + type;

        let value = applyCurrency ? CurrencyHelper.convertTo(this.state[id], this.context.exchangeRate) : this.state[id];
        let validatorOutput = this.validator.message(id, value, rules,
            { messages: { required: translations["validator-required"], max: rangeMessage, min: minMessage, [type]: rangeMessage } });

        return (
            <>
                <Input invalid={!this.validator.fieldValid(id)} type="number"
                    name={id}
                    min={min}
                    max={max}
                    id={id}
                    value={value}
                    step={step}
                    onChange={(e) => this.handleInputChange(applyCurrency, e)} />
                {validatorOutput}
            </>
        );
    }

    render() {
        let returnOnInvestmentTime = '';
        if (this.props.calculatedData["return-on-investment-time"] !== undefined) {
            returnOnInvestmentTime = this.props.calculatedData["return-on-investment-time"];
        }

        return (
            <LanguageContext.Consumer>
                {({ translations }) => (
                    <Card className="main-card">
                        <CardHeader>
                            {translations["return-on-investment"]}
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormGroup row>
                                    <Label for="parking-fees" sm={this.firstColSize}>{translations["parking-fees"]}</Label>
                                    <Col sm={this.secondColSize}>
                                        {this.createInput("parking-fees", true, 0.5, 999, "numeric", translations)}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="paying-fees" sm={this.firstColSize}>{translations["paying-fees"]} <TooltipHelper id="paying-fees">{translations["paying-fees-info"]}</TooltipHelper></Label>
                                    <Col sm={this.secondColSize}>
                                        {this.createInput("paying-fees", false, 0, 99, "integer", translations)}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="week-size" sm={this.firstColSize}>{translations["week-size"]} <TooltipHelper id="week-size">{translations["week-size-info"]}</TooltipHelper></Label>
                                    <Col sm={this.secondColSize}>
                                        {this.createInput("week-size", false, 1, 168, "integer", translations)}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="occupancy" sm={this.firstColSize}>{translations["occupancy"]} <TooltipHelper id="occupancy">{translations["occupancy-info"]}</TooltipHelper></Label>
                                    <Col sm={this.secondColSize}>
                                        {this.createInput("occupancy", false, 1, 100, "integer", translations)}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="current-success-rate" sm={this.firstColSize}>{translations["current-success-rate"]} <TooltipHelper id="current-success-rate">{translations["current-success-rate-info"]}</TooltipHelper></Label>
                                    <Col sm={this.secondColSize}>
                                        {this.createInput("current-success-rate", false, 0, 99, "integer", translations)}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="expected-success-rate" sm={this.firstColSize}>{translations["expected-success-rate"]} <TooltipHelper id="expected-success-rate">{translations["expected-success-rate-info"]}</TooltipHelper></Label>
                                    <Col sm={this.secondColSize}>
                                        {this.createInput("expected-success-rate", false, 0, 99, "integer", translations, this.state["current-success-rate"])}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={{ size: this.firstColSize, offset: this.secondColSize }}>
                                        <Button style={{ float: 'right' }} color="primary" onClick={this.handleCalculateReturns}>{translations["return-on-investment-button"]}</Button>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="return-on-investment-time" sm={this.firstColSize}>{translations["return-on-investment-time"]}</Label>
                                    <Col sm={this.secondColSize}>
                                        <Input type="text" name="return-on-investment-time" id="return-on-investment-time" readOnly value={returnOnInvestmentTime} />
                                    </Col>
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>
                )}
            </LanguageContext.Consumer>
        );
    }
}


ReturnOnInvestment.contextType = CurrencyContext;

export { ReturnOnInvestment };