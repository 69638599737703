import React, { Component } from 'react';
import { Form, FormGroup, Label, Col, Input, Card, CardBody, CardHeader, Button } from 'reactstrap';
import { LanguageContext } from './LanguageContext';
import { TooltipHelper } from './TooltipHelper';
import { CurrencyContext, CurrencyHelper } from './CurrencyContext';

class RunningCosts extends Component {
    constructor(props) {
        super(props);
        this.handleCalculateCosts = this.handleCalculateCosts.bind(this);

        this.firstColSize = props.firstColSize === undefined ? 4 : props.firstColSize;
        this.secondColSize = props.secondColSize === undefined ? 8 : props.secondColSize;
    }

    handleCalculateCosts(e) {
        this.props.onCalculateCosts(e);
    }

    render() {
        let exchangeRate = this.context.exchangeRate;
        return (
            <LanguageContext.Consumer>
                {({ translations }) => (
                    <Card className="main-card">
                        <CardHeader>
                            {translations["running-costs"]}
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormGroup row>
                                    <Label for="connection-fees" sm={this.firstColSize}>{translations["connection-fees"]} <TooltipHelper id="connection-fees">{translations["connection-fees-info"]}</TooltipHelper></Label>
                                    <Col sm={this.secondColSize}>
                                        <Input type="text" name="connection-fees" id="connection-fees" readOnly value={CurrencyHelper.convertTo(this.props.calculatedData["connection-fees"], exchangeRate)} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="electricity-costs" sm={this.firstColSize}>{translations["electricity-costs"]} <TooltipHelper id="electricity-costs">{translations["electricity-costs-info"]}</TooltipHelper></Label>
                                    <Col sm={this.secondColSize}>
                                        <Input type="text" name="electricity-costs" id="electricity-costs" readOnly value={CurrencyHelper.convertTo(this.props.calculatedData["electricity-costs"], exchangeRate)} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="service-fees" sm={this.firstColSize}>{translations["service-fees"]} <TooltipHelper id="service-fees">{translations["service-fees-info"]}</TooltipHelper></Label>
                                    <Col sm={this.secondColSize}>
                                        <Input type="text" name="service-fees" id="service-fees" readOnly value={CurrencyHelper.convertTo(this.props.calculatedData["service-fees"], exchangeRate)} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={{ size: this.firstColSize, offset: this.secondColSize }}>
                                        <Button style={{ float: 'right' }} color="primary" onClick={this.handleCalculateCosts}>{translations["running-costs-button"]}</Button>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="total-running-costs" sm={this.firstColSize}>{translations["total-running-costs"]}</Label>
                                    <Col sm={this.secondColSize}>
                                        <Input type="text" name="total-running-costs" id="total-running-costs" readOnly value={CurrencyHelper.convertTo(this.props.calculatedData["total-running-costs"], exchangeRate)} />
                                    </Col>
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>
                )}
            </LanguageContext.Consumer>
        );
    }
}

RunningCosts.contextType = CurrencyContext;

export { RunningCosts };