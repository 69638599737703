import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';
import Octicon, { Info } from '@githubprimer/octicons-react';

export class TooltipHelper extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false
        };
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    render() {
        return (
            <span id={'Tooltip-' + this.props.id} style={{ color: 'rgba(0,0,0,0.4)'}}>
                <Octicon size={18}>
                    <Info />
                </Octicon>
                <Tooltip placement="right" isOpen={this.state.tooltipOpen} target={'Tooltip-' + this.props.id} toggle={this.toggle}>
                    {this.props.children}
                </Tooltip>
            </span>
        );
    }
}