import React, { Component } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { LanguageContext } from './LanguageContext';

export class LanguageSelector extends Component {


    render() {
        return (
            <LanguageContext.Consumer>
                {({ translations, toggleLanguage, language }) => (
                    <ButtonGroup>
                        <span style={{ marginLeft: '20px'}}>{translations["ui-language"]}</span>
                        <Button color={language === "cs" ? "primary" : "secondary"} onClick={(e) => toggleLanguage(e, "cs")}>Čeština</Button>
                        <Button color={language === "en" ? "primary" : "secondary"} onClick={(e) => toggleLanguage(e, "en")}>English</Button>
                    </ButtonGroup>
                )}
            </LanguageContext.Consumer>
        );
    }
}