import React from 'react';

export const CurrencyContext = React.createContext({
    currency: "",
    exchangeRate: 1
});

export class CurrencyHelper {
    static convertTo(value, exchangeRate) {
        if (value === '')
            return value;

        return Math.round(value * 1 / exchangeRate * 100) / 100; 
    }

    static convertFrom(value, exchangeRate) {
        if (value === '')
            return value;

        return Math.round(value * exchangeRate * 100) / 100;
    }
}