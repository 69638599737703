import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Octicon, { Home } from '@githubprimer/octicons-react';
import './Layout.css';

export class Layout extends Component {

    render() {
        return (
            <>
                
                <Container className="body-content">
                    <Link to="/" className="home-button"><Octicon height={'27px'} width={'27px'} icon={Home} /></Link>
                    {this.props.children}
                </Container>
                <div className="footer">
                    <footer className="container">
                        <Row>
                            <Col sm="4">
                                <img src={process.env.PUBLIC_URL + '/images/SOLEZ_LOGO.png'} alt="Solez Logo" className="solez-logo" />
                            </Col>
                            <Col sm="4">
                                <a href="https://www.cdv.cz/"><img src={process.env.PUBLIC_URL + '/images/Logo_CDV.png'} alt="CDV Logo" className="cdv-logo" /></a>
                            </Col>
                            <Col sm="4">
                                <img src={process.env.PUBLIC_URL + '/images/logo_tree.gif'} alt="Tree Logo" className="tree-logo" />
                            </Col>
                        </Row>
                    </footer>
                </div>
            </>
        );
    }
}