import React, { Component } from 'react';
import { Collapse, Card, CardBody, CardHeader } from 'reactstrap';
import Octicon, { DiffAdded, DiffRemoved } from '@githubprimer/octicons-react';
import { LanguageContext } from './LanguageContext';

export class HardwareComponentsCount extends Component {
    constructor(props) {
        super(props);
        this.state = { isOpen: false };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(state => ({ isOpen: !state.isOpen }));
    }

    render() {
        return (
            <LanguageContext.Consumer>
                {({ translations }) => (
                    <Card style={{ marginBottom: '20px' }}>
                        <CardHeader onClick={this.toggle} style={{ cursor: 'pointer' }}>
                            <span style={{ marginRight: '5px', color: 'grey' }}><Octicon icon={this.state.isOpen ? DiffRemoved : DiffAdded} /></span>
                            {translations["hardware-counts"]}
                        </CardHeader>
                        <Collapse isOpen={this.state.isOpen}>
                            <CardBody>
                                {this.props.children}
                            </CardBody>
                        </Collapse>
                    </Card>
                )}
            </LanguageContext.Consumer>
        );
    }
}