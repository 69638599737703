import React, { Component } from 'react';
import { Route } from 'react-router';
import { Home } from './components/Home';
import { Layout } from './components/Layout';
import { Calculator } from './components/calculator/Calculator';
import { DecisionTool } from './components/DecisionTool/DecisionTool';
import { ImplementationProcess } from './components/ImplementationProcess/ImplementationProcess';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/calculator' component={Calculator} />
                <Route path='/DecisionTool' component={DecisionTool} />
                <Route path='/ImplementationProcess' component={ImplementationProcess} />
            </Layout>
        );
    }
}
