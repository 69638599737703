import React, { Component } from 'react';
import { CurrencySelector } from './CurrencySelector';
import { BuyingCosts } from './BuyingCosts';
import { LanguageSelector } from './LanguageSelector';
import { RunningCosts } from './RunningCosts';
import { ReturnOnInvestment } from './ReturnOnInvestment';

import { LanguageContext } from './LanguageContext';
import { CurrencyContext } from './CurrencyContext';

import './Calculator.css'

export class Calculator extends Component {
    constructor(props) {
        super(props);

        this.firstColSize = 7;
        this.secondColSize = 5;

        this.buyingCosts = React.createRef();

        this.toggleLanguage = (e, language) => {
            this.setState({ languageLoading: true });
            fetch('api/Calculator/Localize?language=' + language)
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        languageLoading: false,
                        translations: data,
                        language: language,
                        calculatedData: Calculator.createEmptyCalculateData()
                    });
                });
            e.preventDefault();
        };

        this.handleCalculateCosts = (e) => {

            let data = { ...this.buyingCosts.current.collectData() };

            fetch('api/Calculator/Calculate', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then(response => response.json())
                .then(data => {
                    this.setState({ calculatedData: data });
                });

            e.preventDefault();
        };

        this.handleCalculateReturns = (data) => {
            let allData = { ...data };
            allData.roi = true;
            allData = { ...allData, ...this.buyingCosts.current.collectData() };

            fetch('api/Calculator/Calculate', {
                method: 'POST',
                body: JSON.stringify(allData),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then(response => response.json())
                .then(data => {
                    this.setState({ calculatedData: data });
                });
        };

        this.toggleCurrency = (currency) => {
            let selectedCurrency = this.state.allCurrencies.find((item) => { return item.code === currency });
            this.setState({ currentCurrency: selectedCurrency.code, exchangeRate: selectedCurrency.exchangeRate });
        };

        this.state = {
            languageLoading: true, translations: {},
            toggleLanguage: this.toggleLanguage,
            currentLanguage: "",
            currencyLoading: true,
            toggleCurrency: this.toggleCurrency,
            currentCurrency: "EUR",
            exchangeRate: 1,
            allCurrencies: {},
            calculatedData: Calculator.createEmptyCalculateData()
        };

        fetch('api/Calculator/Localize?language=en')
            .then(response => response.json())
            .then(data => {
                this.setState({ languageLoading: false, translations: data, language: "en" });
            });

        fetch('api/Calculator/GetAvailableCurrencies')
            .then(response => response.json())
            .then(data => {
                this.setState({ currencyLoading: false, currentCurrency: data[0].code, exchangeRate: data[0].exchangeRate, allCurrencies: data });
            });
    }

    static createEmptyCalculateData() {
        return {
            "node-count": '',
            "router-count": '',
            "coordinator-count": '',
            "hardware-costs": '',
            "setup-costs": '',
            "additional-setup-costs": '',
            "total-buying-costs": '',
            "connection-fees": '',
            "electricity-costs": '',
            "service-fees": '',
            "total-running-costs": '',
            "return-on-investment-time": ''
        };
    }

    renderCalculator() {
        return (
            <>
                <h1>Smart parking calculator</h1>

                <div className="calculator-description">
                    <p>This smart parking calculator will help you estimate the possible investment cost based on the scale of implementation (number of parking spaces equipped with smart on street parking system)</p>
                </div>
                <div className="calculator-body">

                    {/*<LanguageSelector />*/}
                    
                    <BuyingCosts
                        ref={this.buyingCosts}
                        onCalculateCosts={this.handleCalculateCosts}
                        calculatedData={this.state.calculatedData}
                        firstColSize={this.firstColSize}
                        secondColSize={this.secondColSize}
                    />
                    <RunningCosts
                        onCalculateCosts={this.handleCalculateCosts}
                        calculatedData={this.state.calculatedData}
                        firstColSize={this.firstColSize}
                        secondColSize={this.secondColSize}
                    />
                    <ReturnOnInvestment
                        calculatedData={this.state.calculatedData}
                        onCalculateReturns={this.handleCalculateReturns}
                        firstColSize={this.firstColSize}
                        secondColSize={this.secondColSize}
                    />
                    <CurrencySelector />
                </div>

            </>
        );
    }

    render() {
        let contents = (this.state.languageLoading || this.state.currencyLoading)
            ? <p><em>Loading...</em></p>
            : this.renderCalculator();

        return (
            <LanguageContext.Provider value={{ translations: this.state.translations, toggleLanguage: this.state.toggleLanguage, language: this.state.language }}>
                <CurrencyContext.Provider value={{ exchangeRate: this.state.exchangeRate, toggleCurrency: this.state.toggleCurrency, currentCurrency: this.state.currentCurrency, allCurrencies: this.state.allCurrencies }}>
                    {contents}
                </CurrencyContext.Provider>
            </LanguageContext.Provider>
        );
    }
}