import React, { Component } from 'react';
//import { CardDeck, Card, CardBody } from 'reactstrap';
//import { Link } from 'react-router-dom';
import './ImplementationProcess.css';

export class ImplementationProcess extends Component {
    static displayName = ImplementationProcess.name;

    render() {
        return (
            <div>
                <h1>Parking implementation process</h1>
                <p>There are some basic phases behind every successful implementation of smart parking:</p>
                <h2>Phase 1: Analytical part</h2>
                <img src={process.env.PUBLIC_URL + '/images/phase1.jpg'} alt="Phase1" className="phase-img" />
                <ul className="phase-list">
                    <li>Locality selection</li>
                    <li>Digital Passport of existing infrastructure</li>
                    <li>Setting objectives of introducing smart parking tool in selected area</li>
                </ul>
                <h2>Phase 2: Detection and communication technology decision </h2>
                <img src={process.env.PUBLIC_URL + '/images/phase2.jpg'} alt="Phase2" className="phase-img" />
                <ul className="phase-list">
                    <li>Selection of sensor technology for quantification of cars looking for a free parking space</li>
                    <li>Selection of parking sensor technology</li>
                    <li>Selection of Air Quality sensor</li>
                </ul>
                <h2>Phase 3: Evaluation </h2>
                <img src={process.env.PUBLIC_URL + '/images/phase3.jpg'} alt="Phase3" className="phase-img" />
                <ul className="phase-list">
                    <li>Central system network</li>
                    <li>Air Quality scernarios </li>
                </ul>
            </div>
        );
    }
}
