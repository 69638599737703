import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Form, FormGroup, Col, Input, Label, FormFeedback } from 'reactstrap';
import { HardwareComponentsCount } from './HardwareComponentsCount';
import { TooltipHelper } from './TooltipHelper';
import { LanguageContext } from './LanguageContext';
import { CurrencyContext, CurrencyHelper } from './CurrencyContext';
import SimpleReactValidator from 'simple-react-validator';

class BuyingCosts extends Component {
    constructor(props) {
        super(props);
        this.handleCalculateCosts = this.handleCalculateCosts.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.collectData = this.collectData.bind(this);

        this.firstColSize = props.firstColSize === undefined ? 4 : props.firstColSize;
        this.secondColSize = props.secondColSize === undefined ? 8 : props.secondColSize;

        this.validator = new SimpleReactValidator({
            element: message => <FormFeedback tooltip>{message}</FormFeedback>
        });
        this.validator.showMessages();

        this.state = {
            "parking-space-count": "10"
        };
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleCalculateCosts(e) {

        this.props.onCalculateCosts(e);
    }

    collectData() {
        return { "parking-space-count": this.state["parking-space-count"] };
    }

    createInput(id, min, max, requiredMessage, rangeMessage) {
        let rules = 'required|max:' + max + ',num|min:' + min + ',num|integer';
        rangeMessage = rangeMessage.replace('{0}', min);
        rangeMessage = rangeMessage.replace('{1}', max);
        let validatorOutput = this.validator.message(id, this.state[id], rules,
            { messages: { required: requiredMessage, max: rangeMessage, min: rangeMessage, integer: rangeMessage } });
        return (
            <>
                <Input invalid={!this.validator.fieldValid(id)} type="number"
                    name={id}
                    min={min}
                    max={max}
                    id={id}
                    value={this.state[id]}
                    onChange={this.handleInputChange} />
                {validatorOutput}
            </>
        );
    }

    render() {
        let exchangeRate = this.context.exchangeRate;

        return (
            <LanguageContext.Consumer>
                {({ translations }) => (
                    <Card className="main-card">
                        <Form>
                            <CardHeader>
                                {translations["buying-costs"]}
                            </CardHeader>
                            <CardBody>
                                <FormGroup row>
                                    <Label for="parking-space-count" sm={this.firstColSize}>{translations["parking-space-count"]} <TooltipHelper id="parking-space-count">{translations["parking-space-count-info"]}</TooltipHelper></Label>
                                    <Col sm={this.secondColSize}>
                                        {this.createInput("parking-space-count", 1, 999, translations["validator-required"], translations["validator-integer-range"])}
                                    </Col>
                                </FormGroup>
                                <HardwareComponentsCount>
                                    <FormGroup row>
                                        <Label for="node-count" sm={this.firstColSize}>{translations["node-count"]}</Label>
                                        <Col sm={this.secondColSize}>
                                            <Input type="text" name="node-count" id="node-count" readOnly value={this.props.calculatedData["node-count"]} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="router-count" sm={this.firstColSize}>{translations["router-count"]}</Label>
                                        <Col sm={this.secondColSize}>
                                            <Input type="text" name="router-count" id="router-count" readOnly value={this.props.calculatedData["router-count"]} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="coordinator-count" sm={this.firstColSize}>{translations["coordinator-count"]}</Label>
                                        <Col sm={this.secondColSize}>
                                            <Input type="text" name="coordinator-count" id="coordinator-count" readOnly value={this.props.calculatedData["coordinator-count"]} />
                                        </Col>
                                    </FormGroup>
                                </HardwareComponentsCount>
                                <FormGroup row>
                                    <Label for="hardware-costs" sm={this.firstColSize}>{translations["hardware-costs"]} <TooltipHelper id="hardware-costs">{translations["hardware-costs-info"]}</TooltipHelper></Label>
                                    <Col sm={this.secondColSize}>
                                        <Input type="text" name="hardware-costs" id="hardware-costs" readOnly value={CurrencyHelper.convertTo(this.props.calculatedData["hardware-costs"], exchangeRate)} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="setup-costs" sm={this.firstColSize}>{translations["setup-costs"]} <TooltipHelper id="setup-costs">{translations["setup-costs-info"]}</TooltipHelper></Label>
                                    <Col sm={this.secondColSize}>
                                        <Input type="text" name="setup-costs" id="setup-costs" readOnly value={CurrencyHelper.convertTo(this.props.calculatedData["setup-costs"], exchangeRate)} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={{ size: this.firstColSize, offset: this.secondColSize }}>
                                        <Button style={{ float: 'right' }} color="primary" onClick={this.handleCalculateCosts}>{translations["buying-costs-button"]}</Button>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="total-buying-costs" sm={this.firstColSize}>{translations["total-buying-costs"]}</Label>
                                    <Col sm={this.secondColSize}>
                                        <Input type="text" name="total-buying-costs" id="total-buying-costs" readOnly value={CurrencyHelper.convertTo(this.props.calculatedData["total-buying-costs"], exchangeRate)} />
                                    </Col>
                                </FormGroup>
                            </CardBody>
                        </Form>
                    </Card>
                )}
            </LanguageContext.Consumer>
        );
    }
}

BuyingCosts.contextType = CurrencyContext;

export { BuyingCosts };