import React, { Component } from 'react';
//import { CardDeck, Card, CardBody } from 'reactstrap';
//import { Link } from 'react-router-dom';
//import './Home.css';

export class DecisionTool extends Component {
    static displayName = DecisionTool.name;

    render() {
        return (
            <div>
                <h1>Parking technology Decision tool</h1>
                <p>This little questionaire will help us understand your needs and overall situation in the area where you want to implement SMART PARKING solution.</p>
            </div>
        );
    }
}
