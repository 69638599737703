import React, { Component } from 'react';
import { CardDeck, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import './Home.css';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div>
                <h1>SOLEZ - Smart parking tool</h1>
                <p>The majority of European cities have grown around an identifiable centre, where commerce,  entertainment, shopping and political power are concentrated. As a result, city centres are responsible for a relevant part of urban traffic, from/to other urban areas and the hinterlands, where the urbanisation phenomenon is spreading year by year causing an increasing transport demand.  Various instruments can be used to tackle this phenomena, such as SMART PARKING.</p>
                <p>Purpose of this website is to help stakeholders, urban planners to customize the smart parking  approach in their Functional Urban Areas and to help them with decision making process which smart parking solution is the most effective and feasible in their FUA based on the given data. </p>
                <CardDeck>
                    <Link to="/ImplementationProcess">
                        <Card body className="text-center">
                            <CardBody>
                                Parking implementation process
                            </CardBody>
                        </Card>
                    </Link>
                    <Link to="/DecisionTool">
                        <Card body className="text-center">
                            <CardBody>
                                Parking technology<br/>
                                Decision tool
                            </CardBody>
                        </Card>
                    </Link>
                    <Link to="/calculator">
                        <Card body className="text-center">
                            <CardBody>
                                Smart parking calculator
                            </CardBody>
                        </Card>
                    </Link>
                </CardDeck>
            </div>
        );
    }
}
