import React, { Component } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { CurrencyContext } from './CurrencyContext';
import { LanguageContext } from './LanguageContext';
import './CurrencySelector.css';

export class CurrencySelector extends Component {
    render() {
        return (
            <LanguageContext.Consumer>
                {({ translations }) => (
                    <CurrencyContext.Consumer>
                        {({ exchangeRate, toggleCurrency, currentCurrency, allCurrencies }) => (
                            <div className="currency-selector-container">
                                <span className="language-text">{translations["currency"]}</span>
                                <ButtonGroup>
                                    {allCurrencies.map(currency =>
                                        <Button color={currency.code === currentCurrency ? "primary" : "secondary"}
                                            key={currency.code}
                                            onClick={() => toggleCurrency(currency.code)}>
                                            {currency.code}
                                        </Button>
                                    )}
                                </ButtonGroup>
                            </div>
                        )}
                    </CurrencyContext.Consumer>
                )}
            </LanguageContext.Consumer>
        );
    }
}